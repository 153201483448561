import axios from "axios"
import { useState } from "react"
import { EMPLOYEES_API } from "../../../../Utilities/APIs"
import Form from "../../../Partials/Layouts/Forms/Form"
import EmailInput from "../../../Partials/Layouts/Forms/FormInputs/EmailInput/EmailInput"
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"

function CreateInvite({employee, setShowCreateForm, setShowModal, triggerFetch}) {

    const [email, setEmail] = useState('')

    async function handleSubmit(e) {

        e.preventDefault()

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        const itemData = {}

        itemData.email = email
        
        const response = await axios.post(EMPLOYEES_API + 'employeeinvite', itemData, config)

        if (response) {
            setEmail('')

            setShowCreateForm(false)
            setShowModal(false)
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit}>
            <EmailInput
                label={'Email'}
                value={email}
                placeholder={'Please Enter an Email'}
                setState={setEmail}
            />
            <FormSubmitButton text='Send Invite to Email' />
        </Form>
    )

}

export default CreateInvite